import { DEADLETTER } from "../../../constants/urlDefs"
import useHandleRequest from "../../../hooks/useHandleRequest"

import makeRequest from "../../../utils/make-request"

const useReplayDeadletterEventByTopicAndQueue = () => {
  const { error, loading, performAction } = useHandleRequest(
    (
      handleUnauthenticated,
      topic?: string,
      queue?: string,
    ): Promise<{ events: number; replayed: number }> => {
      return makeRequest<{ events: number; replayed: number }>(
        `${DEADLETTER}/retry`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            topic: topic === "" ? undefined : topic,
            queue: queue === "" ? undefined : queue,
          }),
        },
        { handleUnauthenticated },
      )
    },
  )

  return { replayDeadletterEventByTopicAndQueue: performAction, loading, error }
}

export default useReplayDeadletterEventByTopicAndQueue
