export enum DomainStatusEnum {
  claimedUnregistered = "claimed-unregistered",
  claimed = "claimed",
  created = "created",
  managed = "managed",
  partiallySetupRoot = "partially-setup-root",
  partiallySetupWww = "partially-setup-www",
  ready = "ready",
  registered = "registered",
  setup = "setup",
  unconfigured = "unconfigured",
  unknown = "unknown",
  unregistered = "unregistered",
}

export interface Domain {
  id: number
  url: string | null
  user_id: number | null
  status: DomainStatusEnum
  enabled?: boolean
  created_at: Date | null
  external?: boolean
  site_id?: number | null
  last_successful_liveliness_check: Date | null
  subscriptions: DomainSub[]
  deleted_at?: Date | null
}

export type DomainSub = {
  id: number
  status: string
  sk_product_id: string
  auto_renew_status: boolean
}

export type PutDomainStatus = {
  external: boolean
  status: DomainStatusEnum
}

export enum SubdomainStatusEnum {
  setup = "setup",
}

export interface Subdomain {
  id: number
  subdomain: string | null
  site_id?: number | null
  user_id: number | null
  status: SubdomainStatusEnum
  created_at: Date | null
  type: DomainTypeEnum.Subdomain
}

export interface DNSimpleZone {
  id?: string | number
  zone_id?: string | number
  parent_id?: string | number
  name?: string
  content?: string
  type?: string
  ttl?: number
}

export interface DNSimpleData {
  auto_renew?: boolean
  created_at: Date
  expires_at?: Date
  state?: string
  zones?: DNSimpleZone[]
}

export interface VercelVerificationResponse {
  domain: string
  reason: string
  type: string
  value: string
}

export interface VercelData {
  accepted_challenges?: string[]
  conflicts?: string[]
  misconfigured?: boolean
  name: string
  verified?: boolean
  verification?: VercelVerificationResponse[]
}

export interface VercelResponse {
  root?: VercelData
  www?: VercelData
}

export interface DomainDiagnostic {
  domain: Domain
  dnsimple: DNSimpleData
  vercel: VercelResponse
}

export enum DomainTypeEnum {
  Domain = "domain",
  Subdomain = "subdomain",
}

export type DomainInstruction = {
  id: number
  name: string
  instructions: string
  icon_url: string
}

export type CreateDNSRecordRequest = {
  name: string | null
  record_type: string | null
  priority: number | null
  content: string | null
}

export type AddApplePayRequest = {
  domain: string | null
}

export interface DomainSearchResults {
  status: DomainSearch[]
}

export interface DomainSearch {
  domain: string
  status: string
  summary: string
  zone: string
}

export type BlockedDomainPhrases = {
  id: number
  phrase: string
  type: string
  created_at: Date
  updated_at: Date
}

export type DomainCertificate = {
  id: number
  auto_renew: boolean
  domain: string
  expires_at: string
  created_at: string
}

export type DomainPropagation = {
  server: {
    provider: string
    country: string
    emoji: string
  }
  type: string
  results: string[]
}
